import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

const ItemWrapper = ({ children, ...restProps }) => {
  return (
    <Grid item xs={6} sm="auto" {...restProps}>
      {children}
    </Grid>
  );
};

export default ItemWrapper;

ItemWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
