import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

/**
 *
 * @param {String} key The key to set in query for this value
 * @param {Object} defaultValue The value to use if it is not already in query
 * @return {[Object,Function]} returns [state, setState] array
 */
function useQueryParamsState(key, defaultValue = '') {
  const router = useRouter();
  let { query } = router;

  const [state, setState] = useState(() => {
    const valueInQuery = query[key];
    if (valueInQuery) {
      return valueInQuery;
    }
    return typeof defaultValue === 'function' ? defaultValue() : defaultValue || '';
  });

  useEffect(() => {
    if (!state) {
      delete query[key];
    } else {
      query = {
        ...query,
        [key]: state,
      };
    }
    router.push(
      {
        ...router,
        query: { ...query },
      },
      undefined,
      { shallow: true }
    );
  }, [state]);

  return [state, setState];
}

export { useQueryParamsState };
