/* eslint-disable react/jsx-curly-brace-presence */
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/** Components */
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from '@mui/material';

/** Hooks */
import useAuth from 'hooks/useAuth';
import useFilters from 'hooks/useFilters';
import useResponsive from 'hooks/useResponsive';
import { styled, useTheme } from '@mui/material/styles';

/** Icons */
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

/** Blocks */
import Filters from 'blocks/Filters';

import { sortByListUpdated, filterUrlQueryParams, sortByStorePageList } from 'constants/category-filters';

/** Filters */
import PriceFilter from './PriceFilter';
import FlairsFilter from './FlairsFilter';
import SortByFilter from './SortByFilter';
import BundleFilter from './BundleFilter';
import OnOfferFilter from './OnOfferFilter';
import LeadTimeFilter from './LeadTimeFilter';
import CountriesFilter from './CountriesFilter';
import CategoriesFilter from './CategoriesFilter';
import BrandValuesFilter from './BrandValuesFilter';
import CollectionsFilter from './CollectionsFilter';
import MinimumAmountFilter from './MinimumAmountFilter';
import BrandsYouMightLikeFilter from './BrandsYouMightLikeFilter';
import SortByStoreFilter from './SortByStorePageFilter';

const SkeletonLoader = () => <Skeleton height="80px" width="100%" />;

export const VIRGO_FILTERS = {
  PRICE: 'PRICE',
  BUNDLE: 'BUNDLE',
  SORT_BY: 'SORT_BY',
  ON_OFFER: 'ON_OFFER',
  MIN_ORDER: 'MIN_ORDER',
  COUNTRIES: 'COUNTRIES',
  CATEGORIES: 'CATEGORIES',
  BRAND_VALUE: 'BRAND_VALUE',
  COLLECTIONS: 'COLLECTIONS',
  SORT_BY_STORE_PAGE: 'SORT_BY_STORE_PAGE',
  LEAD_TIME_MAX_DAYS: 'LEAD_TIME_MAX_DAYS',
  BRANDS_YOU_MIGHT_LIKE: 'BRANDS_YOU_MIGHT_LIKE',
  FLAIRS: 'FLAIRS',
};

const FILTERS = {
  [VIRGO_FILTERS.PRICE]: ({ ...props }) => <PriceFilter {...props} />,
  [VIRGO_FILTERS.BUNDLE]: ({ ...props }) => <BundleFilter {...props} />,
  [VIRGO_FILTERS.SORT_BY]: ({ ...props }) => <SortByFilter {...props} />,
  [VIRGO_FILTERS.ON_OFFER]: ({ ...props }) => <OnOfferFilter {...props} />,
  [VIRGO_FILTERS.COUNTRIES]: ({ ...props }) => <CountriesFilter {...props} />,
  [VIRGO_FILTERS.CATEGORIES]: ({ ...props }) => <CategoriesFilter {...props} />,
  [VIRGO_FILTERS.MIN_ORDER]: ({ ...props }) => <MinimumAmountFilter {...props} />,
  [VIRGO_FILTERS.BRAND_VALUE]: ({ ...props }) => <BrandValuesFilter {...props} />,
  [VIRGO_FILTERS.COLLECTIONS]: ({ ...props }) => <CollectionsFilter {...props} />,
  [VIRGO_FILTERS.LEAD_TIME_MAX_DAYS]: ({ ...props }) => <LeadTimeFilter {...props} />,
  [VIRGO_FILTERS.SORT_BY_STORE_PAGE]: ({ ...props }) => <SortByStoreFilter {...props} />,
  [VIRGO_FILTERS.BRANDS_YOU_MIGHT_LIKE]: ({ ...props }) => <BrandsYouMightLikeFilter {...props} />,
  [VIRGO_FILTERS.FLAIRS]: ({ ...props }) => <FlairsFilter {...props} />,
};

const VirgoFiltersDefaultPropsSx = {};
const VirgoFiltersDefaultPropsFilters = [{ id: 'country' }];

const VirgoFilters = ({
  filters = VirgoFiltersDefaultPropsFilters,
  sx = VirgoFiltersDefaultPropsSx,
  loading = false,
  splitIndex = false,
  isStorePage = false,
  defaultTab = null,
}) => {
  const theme = useTheme();
  const router = useRouter();
  const { setShowLoadingState, setSelectedSortBy, addFiltersToStack, clearAllFilters } = useFilters();
  const { isAuthenticated } = useAuth();
  const isTablet = useResponsive('down', 'md');
  const [showDrawer, setShowDrawer] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const sortListToUse = isStorePage ? sortByStorePageList : sortByListUpdated;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleSortToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  const handleFiltersToggle = () => {
    setShowDrawer((prev) => !prev);
  };

  const handleFiltersClose = () => {
    setShowDrawer(false);
  };

  const handleSortByChange = (sortByValue) => {
    setShowLoadingState(true);

    setSelectedSortBy([...sortByValue]);

    addFiltersToStack({
      filterKey: filterUrlQueryParams.SORT_BY,
      filterValues: sortByValue.toLowerCase(),
    });

    const isPrice = sortByValue.toLowerCase() === 'wsp' ? { sortDirection: 'asc' } : {};

    router.push(
      {
        ...router,
        query: {
          ...router.query,
          [filterUrlQueryParams.SORT_BY]: sortByValue.toLowerCase(),
          ...isPrice,
        },
      },
      undefined,
      { scroll: false }
    );

    handleClose();
  };

  const handleClearAllFilters = () => {
    clearAllFilters();
  };

  const filtersToRender = (
    <Filters sx={{ ...sx, alignItems: 'center', justifyContent: 'center' }}>
      {filters.map((filter, currentIndex) => {
        if (filter.authProtected) {
          // Check if the user is authenticated before rendering the filter item
          if (!isAuthenticated) return null;

          return (
            <Filters.Item
              key={filter.id}
              {...(splitIndex &&
                currentIndex === splitIndex && {
                  sx: {
                    [theme.breakpoints.up('md')]: {
                      ml: 'auto',
                    },
                  },
                })}
            >
              {loading ? <SkeletonLoader /> : FILTERS[filter.id]({ ...filter?.componentProps, defaultTab })}
            </Filters.Item>
          );
        }

        // Render non-auth protected items as usual
        return (
          <Filters.Item
            key={filter.id}
            {...(splitIndex &&
              currentIndex === splitIndex && {
                sx: {
                  [theme.breakpoints.up('md')]: {
                    ml: 'auto',
                  },
                },
              })}
          >
            {loading ? <SkeletonLoader /> : FILTERS[filter.id]({ ...filter?.componentProps, defaultTab })}
          </Filters.Item>
        );
      })}

      {!isTablet && (
        <Filters.Item sx={{ textAlign: 'center' }}>
          <Link
            underline="always"
            onClick={handleClearAllFilters}
            variant="button"
            color="grey.700"
            component="button"
            sx={{
              textUnderlineOffset: '0.25rem',
              '&:hover': {
                color: 'primary.main',
              },
            }}
          >
            CLEAR ALL FILTERS
          </Link>
        </Filters.Item>
      )}

      {/* FOR HAMBURGER  */}
      {isTablet && (
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            [theme.breakpoints.down('md')]: {
              flexBasis: 'initial',
              mt: 'auto',
            },
          }}
        >
          <Link
            underline="always"
            onClick={handleClearAllFilters}
            variant="button"
            color="grey.700"
            component="button"
            sx={{
              width: '100%',
              marginBottom: '0.5rem',
              textUnderlineOffset: '0.25rem',
              '&:hover': {
                color: 'primary.main',
              },
            }}
          >
            CLEAR ALL FILTERS
          </Link>
          <Button variant="contained" onClick={handleFiltersClose} fullWidth>
            See {router?.query?.tab === 'brands' ? 'Brands' : 'Products'}
          </Button>
        </Grid>
      )}
    </Filters>
  );

  return (
    <>
      {isTablet && (
        <Grid
          container
          justifyContent="center"
          sx={{
            position: 'sticky',
            top: 98,
            zIndex: 2,
            backgroundColor: 'white',
            width: '100%',
            boxShadow: '0px 12px 24px -4px #919EAB29',
            height: '40px',
            transition: theme.transitions.create('all', {
              duration: theme.transitions.duration.standard,
            }),
          }}
        >
          <Grid item xs={5} sx={{ paddingRight: '8px', paddingLeft: '8px' }}>
            <Button
              onClick={handleSortToggle}
              fullWidth
              aria-controls="sort-menu"
              aria-haspopup="true"
              endIcon={<KeyboardArrowDownIcon />}
              sx={{ height: '100%', width: '100%' }}
            >
              Sort
            </Button>
            <Menu
              id="sort-menu"
              elevation={1}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              sx={{ mt: '-1px', boxShadow: '0px 12px 24px -4px #919EAB29 ' }}
            >
              {sortListToUse.map((sortByValue) => (
                <MenuItem
                  key={sortByValue.value}
                  onClick={() => handleSortByChange(sortByValue.value)}
                  sx={{ width: '100%' }}
                >
                  {sortByValue.text}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
          <Grid item xs={1} display={'flex'} alignItems={'center'}>
            <Divider orientation="vertical" sx={{ height: '20px', width: '50%' }} />
          </Grid>
          <Grid item xs={5} sx={{ paddingRight: '8px', paddingLeft: '8px' }}>
            <Button onClick={handleFiltersToggle} fullWidth endIcon={<TuneIcon />} sx={{ height: '100%' }}>
              Filter
            </Button>
          </Grid>
        </Grid>
      )}

      {isTablet ? (
        <>
          <Fade in={showDrawer}>
            <CloseIconWrapper onClick={handleFiltersClose}>
              <CloseIcon sx={{ fill: 'white', width: '100%', height: '100%' }} />
            </CloseIconWrapper>
          </Fade>
          <Drawer
            anchor="right"
            variant="temporary"
            open={showDrawer}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
              onClose: handleFiltersClose,
            }}
            sx={{
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '85%' },
            }}
          >
            {filtersToRender}
          </Drawer>
        </>
      ) : (
        filtersToRender
      )}
    </>
  );
};

export default VirgoFilters;

const CloseIconWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  left: '0',
  top: theme.spacing(0.375),
  zIndex: 1300,
  width: '15vw',
}));

VirgoFilters.propTypes = {
  loading: PropTypes.bool,
  sx: PropTypes.shape({}),
  splitIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOf([
        VIRGO_FILTERS.PRICE,
        VIRGO_FILTERS.BUNDLE,
        VIRGO_FILTERS.FLAIRS,
        VIRGO_FILTERS.SORT_BY,
        VIRGO_FILTERS.ON_OFFER,
        VIRGO_FILTERS.COUNTRIES,
        VIRGO_FILTERS.CATEGORIES,
        VIRGO_FILTERS.MIN_ORDER,
        VIRGO_FILTERS.BRAND_VALUE,
        VIRGO_FILTERS.COLLECTIONS,
        VIRGO_FILTERS.LEAD_TIME_MAX_DAYS,
        VIRGO_FILTERS.SORT_BY_STORE_PAGE,
        VIRGO_FILTERS.BRANDS_YOU_MIGHT_LIKE,
      ]),
    })
  ),
  isStorePage: PropTypes.bool,
  defaultTab: PropTypes.string,
};
