import { useRouter } from 'next/router';

// mui
import ToggleButton from '@mui/material/ToggleButton';
import CheckIcon from '@mui/icons-material/Check';

// hooks
import useFilters from 'hooks/useFilters';

// constants
import { filterUrlQueryParams } from 'constants/category-filters';

const OnOfferFilter = () => {
  const router = useRouter();
  const { setShowLoadingState, setOnOffer, onOffer, addFiltersToStack, handleScrollToTop } = useFilters();

  const handleOnOfferChange = () => {
    handleScrollToTop();

    setShowLoadingState(true);

    addFiltersToStack({ filterKey: filterUrlQueryParams.ON_OFFER, filterValues: String(!onOffer) });

    setOnOffer((prevValue) => {
      router.push(
        {
          ...router,
          query: {
            ...router.query,
            [filterUrlQueryParams.ON_OFFER]: String(!prevValue),
          },
        },
        undefined,
        { scroll: false }
      );
      return !prevValue;
    });
  };

  return (
    <ToggleButton
      fullWidth
      value="check"
      selected={onOffer}
      onChange={handleOnOfferChange}
      data-testid="category-filters__button-on-offer"
      sx={{ height: 56, justifyContent: 'flex-start' }}
    >
      {onOffer && <CheckIcon sx={{ mr: 0.5 }} />}
      <span>On Offer</span>
    </ToggleButton>
  );
};

export default OnOfferFilter;
