import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

// mui
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// hooks
import useTopBar from 'hooks/useTopBar';
import useFilters from 'hooks/useFilters';

// constants
import { filterUrlQueryParams } from 'constants/category-filters';

const FlairsFilter = ({ defaultTab = null }) => {
  const router = useRouter();
  const { query } = router;
  const { productFlairs, brandFlairs, brandFlairsIdentifier } = useTopBar();
  const { selectedFlairs, setShowLoadingState, setSelectedFlairs, addFiltersToStack, handleScrollToTop } = useFilters();

  const flairsToBeShown = {
    brands: brandFlairs?.concat(productFlairs),
    products: productFlairs,
  };

  const flairsToShow = flairsToBeShown[query?.tab || defaultTab];

  useEffect(() => {
    if (query?.flairs && query?.tab === 'products') {
      const appliedFlairs = query?.flairs?.split(',');
      const isAnyBrandFlairApplied = brandFlairsIdentifier?.filter((flair) => appliedFlairs.includes(flair));

      if (isAnyBrandFlairApplied.length <= 0) return;

      const onlyProductFlairs = appliedFlairs?.reduce((acc, flairIdentifier) => {
        if (!brandFlairsIdentifier?.includes(flairIdentifier)) {
          acc.push(flairIdentifier);
        }
        return acc;
      }, []);

      setSelectedFlairs(onlyProductFlairs);

      router.push(
        {
          ...router,
          query: {
            ...router.query,
            [filterUrlQueryParams.FLAIRS]: onlyProductFlairs.map((e) => e.toLowerCase()).join(','),
          },
        },
        undefined,
        { scroll: false }
      );
    }
  }, [brandFlairsIdentifier, query, router, setSelectedFlairs]);

  const handleOnFlairsChange = (event) => {
    handleScrollToTop();

    const {
      target: { value },
    } = event;

    setShowLoadingState(true);

    setSelectedFlairs(
      // On autofill, we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );

    addFiltersToStack({ filterKey: filterUrlQueryParams.FLAIRS, filterValues: value });

    if (value.length) {
      return router.push(
        {
          ...router,
          query: {
            ...router.query,
            [filterUrlQueryParams.FLAIRS]: value.map((e) => e.toLowerCase()).join(','),
          },
        },
        undefined,
        { scroll: false }
      );
    }

    return router.push(
      {
        ...router,
        query: Object.fromEntries(Object.entries(query).filter(([key]) => key !== filterUrlQueryParams.FLAIRS)),
      },
      undefined,
      { scroll: false }
    );
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="flair-select-label">Badge</InputLabel>

      <Select
        labelId="flair-select-label"
        id="flair-select"
        multiple
        value={selectedFlairs}
        onChange={handleOnFlairsChange}
        IconComponent={KeyboardArrowDownIcon}
        input={<OutlinedInput label="Flairs" />}
        inputProps={{ 'data-testid': 'flair-filters__input-categories' }}
        renderValue={() => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selectedFlairs?.map((value) => (
              <Chip
                key={value}
                label={
                  flairsToShow?.find((flair) => {
                    const data = flair?.flairType === 'static' ? flair?.flairId : flair?.code;
                    return data === value;
                  })?.flairTitle
                }
                size="small"
              />
            ))}
          </Box>
        )}
      >
        {flairsToShow?.map((flair) => (
          <MenuItem
            value={flair?.flairType === 'static' ? flair?.flairId : flair?.code}
            key={`flair-${flair?.flairTitle}`}
            data-testid={`flair-filters__option-flair--${flair?.flairId}`}
          >
            <Checkbox
              checked={selectedFlairs?.indexOf(flair?.flairType === 'static' ? flair?.flairId : flair?.code) > -1}
            />

            <ListItemText primary={flair?.flairTitle} secondary="" />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

FlairsFilter.propTypes = {
  defaultTab: PropTypes.string,
};

export default FlairsFilter;
