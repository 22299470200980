// mui
import ToggleButton from '@mui/material/ToggleButton';

// hooks
import { useRouter } from 'next/router';
import useFilters from 'hooks/useFilters';

const BrandsYouMightLikeFilter = () => {
  const router = useRouter();
  const { handleScrollToTop } = useFilters();

  const handleBrandsYouMightLikeClick = async () => {
    handleScrollToTop();

    const path = router.asPath;
    let endpoint = '/new-in?ref=similar_brands';

    // keep the filters
    if (path.includes('?')) {
      const params = new URLSearchParams(router.query);

      // delete the tab param so we can always route to brand tab
      params.delete('tab');

      // delete slug because we won't use it
      params.delete('slug');

      const filters = params.toString();
      endpoint += `&${filters}`;
    }
    await router.push(endpoint, undefined, { scroll: false });
  };

  return (
    <ToggleButton
      fullWidth
      value="check"
      onClick={handleBrandsYouMightLikeClick}
      data-testid="category-filters__button-brands-might-like"
      sx={{ height: 56, justifyContent: 'flex-start' }}
    >
      <span>Brands You Might Like</span>
    </ToggleButton>
  );
};

export default BrandsYouMightLikeFilter;
