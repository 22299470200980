import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import ToggleButton from '@mui/material/ToggleButton';

import { noOp } from 'utils/functions';

const Toggle = ({ isActive = false, handleToggleChange = noOp, label = '' }) => {
  return (
    <ToggleButton
      fullWidth
      value="check"
      selected={isActive}
      onChange={handleToggleChange}
      data-testid={`category-filters__button-${label}`}
      sx={{ height: 56, justifyContent: 'flex-start' }}
    >
      {isActive && <CheckIcon sx={{ mr: 0.5 }} />}
      <span>{label}</span>
    </ToggleButton>
  );
};

export default Toggle;

Toggle.propTypes = {
  label: PropTypes.string,
  isActive: PropTypes.bool,
  handleToggleChange: PropTypes.func,
};
