import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import { useTheme } from '@mui/material/styles';

const filtersItemDefaultPropSx = {};

const FiltersItem = ({ sx = filtersItemDefaultPropSx, children, ...restProps }) => {
  const theme = useTheme();

  return (
    <Grid
      item
      xs={12}
      md={1.7}
      sx={{
        [theme.breakpoints.down('md')]: {
          flexBasis: 'initial',
        },
        ...sx,
      }}
      {...restProps}
    >
      {children}
    </Grid>
  );
};

FiltersItem.propTypes = {
  sx: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

export default FiltersItem;
