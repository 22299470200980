import PropTypes from 'prop-types';

/** Hooks */
import useFilters from 'hooks/useFilters';
import useBrowserQueryParams from 'hooks/utils/useBrowserQueryParams';

/** Const */
import { filterUrlQueryParams } from 'constants/category-filters';

/** Components */
import SelectCheckbox from '../common/SelectCheckbox';

const collectionsFilterDefaultPropSelectOptions = [{}];

const CollectionsFilter = ({ selectOptions = collectionsFilterDefaultPropSelectOptions }) => {
  const { dispatch, ACTION_TYPES, filtersState, setShowLoadingState, handleScrollToTop } = useFilters();
  const { updateBrowserQueryParams, deleteBrowserQueryParams } = useBrowserQueryParams();

  const handleOnChange = ({ target: { value } }) => {
    handleScrollToTop();

    setShowLoadingState(true);

    const selectedCollections = typeof value === 'string' ? value.split(',') : value;

    const stateUpdate = { collections: selectedCollections };
    const queryUpdate = { [filterUrlQueryParams.COLLECTION]: selectedCollections.join(',') };

    dispatch({ type: ACTION_TYPES.UPDATE_FILTER, stateUpdate });

    if (value.length) {
      updateBrowserQueryParams(queryUpdate);
    } else {
      deleteBrowserQueryParams(filterUrlQueryParams.COLLECTION);
    }
  };

  return (
    <SelectCheckbox
      label="collections"
      selectOptions={selectOptions}
      handleOnChange={handleOnChange}
      selectedValues={filtersState.collections}
    />
  );
};

export default CollectionsFilter;

CollectionsFilter.propTypes = {
  selectOptions: PropTypes.arrayOf(PropTypes.shape({})),
};
