import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

const AdvertisingWrapper = ({ children, ...restProps }) => {
  return (
    <Grid item xs={12} sm="auto" {...restProps}>
      {children}
    </Grid>
  );
};

export default AdvertisingWrapper;

AdvertisingWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
