import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useReducer, useRef, useState } from 'react';

// context
import FiltersContext from 'context/FiltersContext';

// hooks
import useAuth from 'hooks/useAuth';
import useTopBar from 'hooks/useTopBar';
import useCurrency from 'hooks/useCurrency';
import useResponsive from 'hooks/useResponsive';

// utils
import { stringToBoolMap } from 'utils/functions';
import { setInitialFilters } from 'utils/filter';
import { removeFromQueryString } from 'utils/url-query';

// constants
import { DEFAULT_SORT_BY_VALUE } from 'constants/listing-defaults';
import { minOrderList, leadTimesList, getPriceList, filterUrlQueryParams } from 'constants/category-filters';
import { GTMFiltersUsedEvent } from 'utils/gtm';

const ACTION_TYPES = {
  CLEAR: 'CLEAR',
  UPDATE_FILTER: 'UPDATE_FILTER',
};

const multipleFilterValueKeys = [
  filterUrlQueryParams.COUNTRIES,
  filterUrlQueryParams.CATEGORY,
  filterUrlQueryParams.BRAND_VALUE,
  filterUrlQueryParams.FLAIRS,
];

const radioButtons = [
  filterUrlQueryParams.MIN_ORDER,
  filterUrlQueryParams.LEAD_TIME_MAX_DAYS,
  filterUrlQueryParams.SORT_BY,
];

function filtersReducer(state, { type, stateUpdate, newState }) {
  switch (type) {
    case ACTION_TYPES.CLEAR: {
      return newState;
    }
    case ACTION_TYPES.UPDATE_FILTER: {
      return { ...state, ...stateUpdate };
    }
    default: {
      throw new Error(`Unsupported type: ${type}`);
    }
  }
}

const filtersProviderDefaultPropInitialQueryParams = {};

const FiltersProvider = ({ children, initialQueryParams = filtersProviderDefaultPropInitialQueryParams }) => {
  const router = useRouter();
  const layoutRef = useRef(null);
  const { userId } = useAuth();
  const { currency } = useCurrency();
  const isTablet = useResponsive('down', 'md');
  const { brandFlairs, productFlairs } = useTopBar();
  const [filtersStack, setFiltersStack] = useState([]);

  const allFlairFilterOptions = useMemo(
    () => [...(brandFlairs?.length > 0 ? brandFlairs : []), ...(productFlairs?.length > 0 ? productFlairs : [])],
    [brandFlairs, productFlairs]
  );

  /** Query Values */
  const {
    // initialTab,
    sortByQueryValue,
    onOfferQueryValue,
    leadTimeQueryValue,
    minOrderQueryValue,
    countriesQueryValue,
    categoriesQueryValue,
    brandValuesQueryValue,
    priceQueryValue,
    bundleQueryValue,
    storeSortByQueryValue,
    currencyQueryValue,
    collectionsQueryValue,
    flairsQueryValue,
  } = setInitialFilters(initialQueryParams, allFlairFilterOptions);

  const [selectedFlairs, setSelectedFlairs] = useState(flairsQueryValue);
  const [selectedCountries, setSelectedCountries] = useState(countriesQueryValue);
  const [selectedCategories, setSelectedCategories] = useState(categoriesQueryValue);
  const [selectedMinOrder, setSelectedMinOrder] = useState(minOrderQueryValue || '');
  const [onOffer, setOnOffer] = useState(stringToBoolMap[onOfferQueryValue] || false);
  const [selectedBrandValues, setSelectedBrandValues] = useState(brandValuesQueryValue);
  const [selectedLeadTime, setSelectedLeadTime] = useState(leadTimeQueryValue?.text || '');
  const [selectedSortBy, setSelectedSortBy] = useState(sortByQueryValue ? [sortByQueryValue] : [DEFAULT_SORT_BY_VALUE]);

  const handleScrollToTop = () => {
    if (layoutRef?.current && isTablet) {
      const scrollToY = layoutRef.current.getBoundingClientRect().top + window.scrollY - 100;

      Promise.resolve().then(() => {
        window.scrollTo({
          top: scrollToY,
          behavior: 'smooth',
        });
      });
    }
  };

  useEffect(() => {
    setFiltersStack(() => [{ ...router.query }]);
  }, []);

  useEffect(() => {
    const {
      sortByQueryValue: tempsortByQueryValue,
      flairsQueryValue: tempFlairsQueryValue,
      onOfferQueryValue: temponOfferQueryValue,
      leadTimeQueryValue: templeadTimeQueryValue,
      minOrderQueryValue: tempminOrderQueryValue,
      countriesQueryValue: tempcountriesQueryValue,
      categoriesQueryValue: tempcategoriesQueryValue,
      brandValuesQueryValue: tempbrandValuesQueryValue,
    } = setInitialFilters(router.query, allFlairFilterOptions);

    setSelectedFlairs(tempFlairsQueryValue);
    setSelectedCountries(tempcountriesQueryValue);
    setSelectedCategories(tempcategoriesQueryValue);
    setSelectedMinOrder(tempminOrderQueryValue || '');
    setOnOffer(stringToBoolMap[temponOfferQueryValue] || false);
    setSelectedBrandValues(tempbrandValuesQueryValue);
    setSelectedLeadTime(templeadTimeQueryValue?.text || '');
    setSelectedSortBy(tempsortByQueryValue ? [tempsortByQueryValue] : [DEFAULT_SORT_BY_VALUE]);

    if (
      onOffer ||
      !selectedSortBy.includes('Recommended') ||
      selectedFlairs.length ||
      selectedCountries.length ||
      selectedCategories.length ||
      selectedMinOrder ||
      selectedBrandValues.length ||
      selectedLeadTime
    ) {
      GTMFiltersUsedEvent(
        {
          ...(selectedFlairs.length && { selectedFlairs }),
          ...(selectedCountries.length && { selectedCountries }),
          ...(selectedCategories.length && { selectedCategories }),
          ...(selectedMinOrder && { selectedMinOrder }),
          ...(onOffer && { onOffer }),
          ...(selectedBrandValues.length && { selectedBrandValues }),
          ...(selectedLeadTime && { selectedLeadTime }),
          ...(selectedSortBy && { selectedSortBy }),
        },
        router.asPath,
        userId
      );
    }
  }, [allFlairFilterOptions, router.query]);

  // for SSR
  const [showLoadingState, setShowLoadingState] = useState(false);

  /** Reducers for store page filters */
  const initialFiltersStateForStorePage = {
    bundle: stringToBoolMap[bundleQueryValue] || false,
    onOffer: onOfferQueryValue,
    currency: currencyQueryValue,
    collections: collectionsQueryValue || [],
    flairs: flairsQueryValue || [],
    sortBy: storeSortByQueryValue || [DEFAULT_SORT_BY_VALUE.toLowerCase()],
    price: getPriceList(currency.symbol).filter(({ value }) => value === priceQueryValue)[0]?.text,
  };

  const [filtersState, dispatch] = useReducer(filtersReducer, initialFiltersStateForStorePage);

  const addFiltersToStack = ({ filterKey, filterValues }) => {
    const queries = { ...router.query };

    Object.keys(queries).forEach((q) => {
      if (Object.values(filterUrlQueryParams).includes(q)) delete queries[q];
    });

    const newlyAppliedFilters = { ...queries, ...filtersStack[filtersStack.length - 1] };

    if (multipleFilterValueKeys.includes(filterKey)) {
      if (filterValues.length <= 0) delete newlyAppliedFilters[filterKey];
      else newlyAppliedFilters[filterKey] = filterValues.toString();
    }
    if (radioButtons.includes(filterKey)) {
      if (filterValues === '' || filterValues.length <= 0 || !filterValues) delete newlyAppliedFilters[filterKey];
      else newlyAppliedFilters[filterKey] = filterValues;
    }
    if (filterKey === filterUrlQueryParams.ON_OFFER) {
      if (filterValues === false || filterValues === 'false' || !filterValues) delete newlyAppliedFilters[filterKey];
      else newlyAppliedFilters[filterKey] = 'true';
    }
    setFiltersStack((prev) => [...prev, newlyAppliedFilters]);
  };

  const removeFilterFromStack = () => {
    const appliedFilters = [...filtersStack];
    appliedFilters.pop();

    const goToUrl = appliedFilters[appliedFilters.length - 1];

    setSelectedFlairs(goToUrl?.[filterUrlQueryParams.FLAIRS] ? goToUrl?.[filterUrlQueryParams.FLAIRS].split(',') : []);

    setSelectedCountries(
      goToUrl?.[filterUrlQueryParams.COUNTRIES] ? goToUrl?.[filterUrlQueryParams.COUNTRIES].split(',') : []
    );
    setSelectedBrandValues(
      goToUrl?.[filterUrlQueryParams.BRAND_VALUE] ? goToUrl?.[filterUrlQueryParams.BRAND_VALUE].split(',') : []
    );
    setSelectedCategories(
      goToUrl?.[filterUrlQueryParams.CATEGORY] ? goToUrl?.[filterUrlQueryParams.CATEGORY].split(',') : []
    );
    setSelectedFlairs(goToUrl?.[filterUrlQueryParams.FLAIR] ? goToUrl?.[filterUrlQueryParams.FLAIR].split(',') : []);

    setSelectedMinOrder(
      goToUrl?.[filterUrlQueryParams.MIN_ORDER]
        ? minOrderList.find((minValue) => minValue?.slug === goToUrl?.[filterUrlQueryParams.MIN_ORDER])?.text
        : ''
    );

    setSelectedLeadTime(
      goToUrl?.[filterUrlQueryParams.LEAD_TIME_MAX_DAYS]
        ? leadTimesList.find((leadTime) => leadTime?.maximumDays === goToUrl?.[filterUrlQueryParams.LEAD_TIME_MAX_DAYS])
            ?.text
        : ''
    );

    setOnOffer(Boolean(goToUrl?.[filterUrlQueryParams.ON_OFFER]));

    setSelectedSortBy(() => {
      if (!goToUrl?.[filterUrlQueryParams.SORT_BY]) return [DEFAULT_SORT_BY_VALUE];

      const originalString = goToUrl[filterUrlQueryParams.SORT_BY];
      const capitalizedString = originalString.charAt(0).toUpperCase() + originalString.slice(1);
      return [capitalizedString];
    });

    router.query = goToUrl;
    setFiltersStack(() => appliedFilters);
    router.push(router, undefined, { scroll: false });
  };

  const clearAllFilters = () => {
    setSelectedCountries([]);
    setSelectedFlairs([]);
    setSelectedCategories([]);
    setSelectedMinOrder('');
    setSelectedLeadTime('');
    setOnOffer(false);
    setSelectedBrandValues([]);
    setSelectedSortBy([DEFAULT_SORT_BY_VALUE]);

    setFiltersStack([]);
    router.push(
      `${router.asPath.split('?')[0]}${removeFromQueryString(router.asPath.split('?')[1], filterUrlQueryParams)}`,
      undefined,
      {
        scroll: false,
      }
    );
  };

  const isFilterApplied =
    selectedCountries.length > 0 ||
    selectedCategories.length > 0 ||
    selectedMinOrder.length > 0 ||
    selectedLeadTime.length > 0 ||
    selectedFlairs.length > 0 ||
    onOffer ||
    selectedBrandValues.length > 0;

  const state = useMemo(
    () => ({
      onOffer,
      setOnOffer,
      selectedFlairs,
      setSelectedFlairs,
      selectedSortBy,
      selectedMinOrder,
      showLoadingState,
      selectedLeadTime,
      setSelectedSortBy,
      selectedBrandValues,
      setSelectedBrandValues,
      selectedCountries,
      selectedCategories,
      setSelectedMinOrder,
      setSelectedLeadTime,
      setShowLoadingState,
      setSelectedCountries,
      setSelectedCategories,
      dispatch,
      filtersState,
      ACTION_TYPES,
      clearAllFilters,
      addFiltersToStack,
      removeFilterFromStack,
      filtersStack,
      isFilterApplied,
      setFiltersStack,
      layoutRef,
      handleScrollToTop,
    }),
    [
      onOffer,
      selectedFlairs,
      setSelectedFlairs,
      selectedSortBy,
      selectedMinOrder,
      showLoadingState,
      selectedLeadTime,
      selectedBrandValues,
      selectedCountries,
      selectedCategories,
      filtersState,
      isFilterApplied,
      filtersStack,
    ]
  );

  return <FiltersContext.Provider value={state}>{children}</FiltersContext.Provider>;
};

export default FiltersProvider;

FiltersProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialQueryParams: PropTypes.shape({}),
};
