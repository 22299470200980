import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import { useTheme } from '@mui/material/styles';

/** Blocks */
import FiltersItem from './FiltersItem';

const filtersDefaultPropSx = {};

const Filters = ({ sx = filtersDefaultPropSx, children, ...restProps }) => {
  const theme = useTheme();

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={1}
      sx={{
        ...sx,
        [theme.breakpoints.down('md')]: {
          mb: 0,
          flex: 1,
          flexWrap: 'nowrap',
          alignItems: 'stretch',
          flexDirection: 'column',
          padding: theme.spacing(1),
        },
      }}
      {...restProps}
    >
      {children}
    </Grid>
  );
};

Filters.Item = FiltersItem;

Filters.propTypes = {
  sx: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

export default Filters;
