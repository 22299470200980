import omit from 'lodash/omit';
import { useRouter } from 'next/router';

const useBrowserQueryParams = () => {
  const router = useRouter();

  const updateBrowserQueryParams = (queryParamsObject) => {
    const newQueryParams = { ...router.query, ...queryParamsObject };

    if (!queryParamsObject.ref) {
      delete newQueryParams.ref;
    }

    return router.push({
      ...router,
      query: newQueryParams,
    });
  };

  const deleteBrowserQueryParams = async (...queryToRemove) => {
    await router.push({
      ...router,
      query: omit(router.query, queryToRemove),
    });
  };

  return {
    updateBrowserQueryParams,
    deleteBrowserQueryParams,
  };
};

export default useBrowserQueryParams;
