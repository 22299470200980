// hooks
import useFilters from 'hooks/useFilters';

// utils/consts
import useBrowserQueryParams from 'hooks/utils/useBrowserQueryParams';
import { filterUrlQueryParams } from 'constants/category-filters';

// components
import Toggle from '../common/Toggle';

const BundleFilter = () => {
  const { updateBrowserQueryParams } = useBrowserQueryParams();
  const { filtersState, ACTION_TYPES, dispatch, setShowLoadingState, handleScrollToTop } = useFilters();

  const handleBundleChange = () => {
    setShowLoadingState(true);
    handleScrollToTop();

    const bundleValue = !filtersState.bundle;
    const stateUpdate = { bundle: bundleValue };
    const queryUpdate = { [filterUrlQueryParams.BUNDLE]: bundleValue };

    dispatch({ type: ACTION_TYPES.UPDATE_FILTER, stateUpdate });
    updateBrowserQueryParams(queryUpdate);
  };

  return <Toggle label="bundle" handleToggleChange={handleBundleChange} isActive={filtersState.bundle} />;
};

export default BundleFilter;
