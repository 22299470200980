import PropTypes from 'prop-types';

import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { noOp } from 'utils/functions';

const selectRadioDefaultPropSelectOptions = [];

const SelectRadio = ({
  handleOnClose = noOp,
  handleOnChange = noOp,
  selectedValue = '',
  label = '',
  selectOptions = selectRadioDefaultPropSelectOptions,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${label}-select-label`} sx={{ textTransform: 'capitalize' }}>
        {label}
      </InputLabel>
      <Select
        id={`${label}-select`}
        value={selectedValue}
        labelId={`${label}-select-label`}
        onClose={handleOnClose}
        renderValue={(selected) => selected}
        onChange={handleOnChange}
        IconComponent={KeyboardArrowDownIcon}
        input={<OutlinedInput label={label} />}
        inputProps={{ 'data-testid': `category-filters__input-${label}-value` }}
      >
        <MenuItem value="clear" sx={{ justifyContent: 'flex-end', '&.Mui-selected': { backgroundColor: 'initial' } }}>
          <Button variant="contained">Clear</Button>
        </MenuItem>
        {selectOptions.map(({ text, value }) => (
          <MenuItem value={value} key={`price-${value}`}>
            <Radio
              checked={text === selectedValue}
              value={value}
              name={`${label}-value-radio-button`}
              inputProps={{ 'aria-label': `${label}-radio-button-${value}` }}
            />
            <ListItemText primary={text} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectRadio;

SelectRadio.propTypes = {
  label: PropTypes.string,
  handleOnClose: PropTypes.func,
  handleOnChange: PropTypes.func,
  selectedValue: PropTypes.string,
  selectOptions: PropTypes.arrayOf(PropTypes.shape({})),
};
