import { urlQueryParamsMap } from 'constants/query-params';

/**
 * @param endpoint {string}
 * @param queryParamKey {string}
 * @param queryParamValue {any}
 * @return {string}
 */
export const setQueryParam = (endpoint, queryParamKey, queryParamValue) => {
  let values = [queryParamValue];

  if (typeof queryParamValue === 'string' && queryParamValue.includes(',')) {
    values = queryParamValue.split(',');
  }
  const [pathName, queryParams] = endpoint.split('?');

  const param = new URLSearchParams(queryParams);

  param.delete(queryParamKey);

  values.forEach((value) => {
    param.append(queryParamKey, value);
  });

  const newEndpoint = `${pathName}?${param.toString()}`;

  return newEndpoint;
};

export const addQueryParamIfNew = (endpoint, queryParamKey, queryParamValue) => {
  const newEndpoint = endpoint;

  const [path, queryParams] = newEndpoint.split('?');
  const param = new URLSearchParams(queryParams);

  if (param.has(queryParamKey) || (path.length > 0 && path.charAt(path.length - 1) === '#')) {
    return endpoint;
  }

  return setQueryParam(endpoint, queryParamKey, queryParamValue);
};

export const createQueryStringFromObject = (queryParams) => {
  if (typeof queryParams !== 'object') {
    throw new SyntaxError(`'queryParams' must be of type 'object'. '${queryParams}' provided.`);
  }
  const newQuery = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      const queryKey = urlQueryParamsMap[key] ? urlQueryParamsMap[key] : key;
      newQuery.set(queryKey, value);
    }
  });

  const queryString = newQuery.toString() ? `${newQuery.toString()}` : '';

  return queryString;
};

export function createEndpointWithParams(endpoint, queryParams = {}) {
  const paramsString = createQueryStringFromObject(queryParams, true);

  const prefix = endpoint.includes('?') ? '&' : '?';

  return `${endpoint}${prefix}${paramsString}`;
}

export function removeFromQueryString(paramsStr, removeMap) {
  if (!paramsStr || typeof paramsStr !== 'string') return '';
  const safeParamsStr = paramsStr ? paramsStr?.replace(/&/g, '","')?.replace(/=/g, '":"') : '';
  const paramsObj = JSON.parse(`{"${safeParamsStr}"}`, (key, value) => {
    return key === '' ? value : decodeURIComponent(value);
  });

  Object.entries(removeMap).forEach(([, value]) => {
    if (Object.keys(paramsObj).includes(value)) {
      delete paramsObj[value];
    }
  });

  const newParamsStr = createQueryStringFromObject(paramsObj);
  return newParamsStr ? `?${newParamsStr}` : '';
}
