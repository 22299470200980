// hooks
import useFilters from 'hooks/useFilters';
import useBrowserQueryParams from 'hooks/utils/useBrowserQueryParams';

// utils/constants
import { filterUrlQueryParams, sortByStorePageList } from 'constants/category-filters';

// components
import SelectDefault from '../common/SelectDefault';

const SortByStoreFilter = () => {
  const { updateBrowserQueryParams } = useBrowserQueryParams();
  const { filtersState, ACTION_TYPES, dispatch, setShowLoadingState, handleScrollToTop } = useFilters();

  const handleSortByStoreChange = ({ target: { value } }) => {
    handleScrollToTop();

    setShowLoadingState(true);

    const stateUpdate = { sortBy: value.toLowerCase() };
    const isTrending = value.toLowerCase() === 'trending' ? { ref: 'Trending' } : {};
    const isPrice = value.toLowerCase() === 'wsp' ? { sortDirection: 'asc' } : {};

    const queryUpdate = { [filterUrlQueryParams.SORT_BY]: value.toLowerCase(), ...isTrending, ...isPrice };

    dispatch({ type: ACTION_TYPES.UPDATE_FILTER, stateUpdate });
    updateBrowserQueryParams(queryUpdate);
  };

  return (
    <SelectDefault
      label="sort by"
      selectOptions={sortByStorePageList}
      handleSelectDefaultChange={handleSortByStoreChange}
      selectedValue={filtersState.sortBy}
    />
  );
};
export default SortByStoreFilter;
