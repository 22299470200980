import { useRouter } from 'next/router';

// mui
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// hooks
import useAuth from 'hooks/useAuth';
import useFilters from 'hooks/useFilters';

// constants
import { DEFAULT_SORT_BY_VALUE } from 'constants/listing-defaults';
import { filterUrlQueryParams, sortByList } from 'constants/category-filters';

const SortByFilter = () => {
  const router = useRouter();
  const { isAuthenticated } = useAuth();
  const { selectedSortBy, setShowLoadingState, setSelectedSortBy, addFiltersToStack, handleScrollToTop } = useFilters();

  const handleSortByChange = (event) => {
    handleScrollToTop();

    const {
      target: { value },
    } = event;

    setShowLoadingState(true);

    setSelectedSortBy(
      // On autofill, we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );

    addFiltersToStack({
      filterKey: filterUrlQueryParams.SORT_BY,
      filterValues: value === DEFAULT_SORT_BY_VALUE ? '' : value.toLowerCase(),
    });

    return router.push(
      {
        ...router,
        query: {
          ...router.query,
          [filterUrlQueryParams.SORT_BY]: value.toLowerCase(),
        },
      },
      undefined,
      { scroll: false }
    );
  };

  return (
    <FormControl fullWidth disabled={!isAuthenticated}>
      <InputLabel id="sort-by-select-label">Sort By</InputLabel>
      <Select
        labelId="sort-by-select-label"
        id="sort-by-select"
        value={selectedSortBy}
        onChange={handleSortByChange}
        IconComponent={KeyboardArrowDownIcon}
        input={<OutlinedInput label="Sort by" />}
        inputProps={{ 'data-testid': 'category-filters__input-sort' }}
      >
        {sortByList.map((sortByValue) => (
          <MenuItem
            sx={{ color: '#000000' }}
            value={sortByValue}
            key={`sort-by-${sortByValue}`}
            data-testid={`category-filters__input-sort--${sortByValue.toLowerCase()}`}
          >
            {sortByValue}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SortByFilter;
