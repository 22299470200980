import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

const ItemContainer = ({ children, ...restProps }) => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    rowSpacing={{ xs: 1, xl: 2 }}
    columnSpacing={{ xs: 0.75, sm: 0.35, xl: 2 }}
    {...restProps}
  >
    {children}
  </Grid>
);

export default ItemContainer;

ItemContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
