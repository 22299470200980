import { useRouter } from 'next/router';

// mui
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// hooks
import useFilters from 'hooks/useFilters';

// constants
import { filterUrlQueryParams, leadTimesList } from 'constants/category-filters';

const LeadTimeFilter = () => {
  const router = useRouter();
  const { query } = router;
  const { selectedLeadTime, setShowLoadingState, setSelectedLeadTime, addFiltersToStack, handleScrollToTop } =
    useFilters();

  const handleLeadTimeChange = (event) => {
    handleScrollToTop();

    const {
      target: { value },
    } = event;

    setShowLoadingState(true);

    let maximumDays;

    if (value !== 'clear') maximumDays = leadTimesList.find(({ text }) => text === value)?.maximumDays;

    addFiltersToStack({
      filterKey: filterUrlQueryParams.LEAD_TIME_MAX_DAYS,
      filterValues: value === 'clear' ? '' : maximumDays,
    });

    setSelectedLeadTime(() => {
      if (value === 'clear') {
        router.push(
          {
            ...router,
            query: Object.fromEntries(
              Object.entries(query).filter(([key]) => key !== filterUrlQueryParams.LEAD_TIME_MAX_DAYS)
            ),
          },
          undefined,
          { scroll: false }
        );
        return '';
      }

      router.push(
        {
          ...router,
          query: {
            ...router.query,
            [filterUrlQueryParams.LEAD_TIME_MAX_DAYS]: maximumDays,
          },
        },
        undefined,
        { scroll: false }
      );
      return value;
    });
  };

  const handleLeadTimeOnClose = (event) => {
    let selectedPrevValue;

    if (event.target.nodeName === 'INPUT') {
      selectedPrevValue = event.target.value;
    }

    if (event.target.nodeName === 'SPAN' || event.target.nodeName === 'FONT') {
      selectedPrevValue = event.target.innerText;
    }

    if (selectedPrevValue === selectedLeadTime) {
      handleScrollToTop();
      setSelectedLeadTime('');
      router.push(
        {
          ...router,
          query: Object.fromEntries(
            Object.entries(query).filter(([key]) => key !== filterUrlQueryParams.LEAD_TIME_MAX_DAYS)
          ),
        },
        undefined,
        { scroll: false }
      );
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="lead-time-select-label">Lead time</InputLabel>
      <Select
        labelId="lead-time-select-label"
        id="lead-time-select"
        value={selectedLeadTime}
        onChange={handleLeadTimeChange}
        onClose={handleLeadTimeOnClose}
        IconComponent={KeyboardArrowDownIcon}
        input={<OutlinedInput label="lead-time" />}
        renderValue={(selected) => selected}
        inputProps={{ 'data-testid': 'category-filters__input-lead-time-value' }}
      >
        <MenuItem value="clear" sx={{ justifyContent: 'flex-end', '&.Mui-selected': { backgroundColor: 'initial' } }}>
          <Button disabled={!selectedLeadTime || selectedLeadTime?.length <= 0} variant="contained">
            Clear
          </Button>
        </MenuItem>

        {leadTimesList.map((value) => (
          <MenuItem value={value.text} key={`lead-time-${value.text}`}>
            <Radio
              checked={value.text === selectedLeadTime}
              value={value.text}
              name="lead-time__radio-button"
              inputProps={{ 'aria-label': `lead-time__radio-button-${value.text}` }}
            />
            <ListItemText primary={value.text} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LeadTimeFilter;
