import { useRouter } from 'next/router';

// mui
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// hooks
import useFilters from 'hooks/useFilters';

// constants
import { filterUrlQueryParams, minOrderList } from 'constants/category-filters';

const MinimumAmountFilter = () => {
  const router = useRouter();
  const { query } = router;
  const { selectedMinOrder, setShowLoadingState, setSelectedMinOrder, addFiltersToStack, handleScrollToTop } =
    useFilters();

  const handleMinOrderAmountChange = (event) => {
    handleScrollToTop();

    const {
      target: { value },
    } = event;

    setShowLoadingState(true);

    addFiltersToStack({
      filterKey: filterUrlQueryParams.MIN_ORDER,
      filterValues: value === 'clear' ? '' : minOrderList.find((minValue) => minValue.text === value).slug,
    });

    setSelectedMinOrder(() => {
      if (value === 'clear') {
        router.push(
          {
            ...router,
            query: Object.fromEntries(Object.entries(query).filter(([key]) => key !== filterUrlQueryParams.MIN_ORDER)),
          },
          undefined,
          { scroll: false }
        );
        return '';
      }

      const { slug: minOrderSlug } = minOrderList.find((minValue) => minValue.text === value);

      router.push(
        {
          ...router,
          query: {
            ...router.query,
            [filterUrlQueryParams.MIN_ORDER]: minOrderSlug,
          },
        },
        undefined,
        { scroll: false }
      );
      return value;
    });
  };

  const handleMinOrderOnClose = (event) => {
    let selectedPrevValue;

    if (event.target.nodeName === 'INPUT') {
      selectedPrevValue = event.target.value;
    }

    if (event.target.nodeName === 'SPAN' || event.target.nodeName === 'FONT') {
      selectedPrevValue = event.target.innerText;
    }

    if (selectedPrevValue === selectedMinOrder) {
      handleScrollToTop();
      setSelectedMinOrder('');
      router.push({
        ...router,
        query: Object.fromEntries(Object.entries(query).filter(([key]) => key !== filterUrlQueryParams.MIN_ORDER)),
      });
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="minimum-select-label">Minimum</InputLabel>
      <Select
        id="minimum-select"
        value={selectedMinOrder}
        labelId="minimum-select-label"
        onClose={handleMinOrderOnClose}
        renderValue={(selected) => selected}
        onChange={handleMinOrderAmountChange}
        IconComponent={KeyboardArrowDownIcon}
        input={<OutlinedInput label="Minimum" />}
        inputProps={{ 'data-testid': 'category-filters__input-minimum-value' }}
      >
        <MenuItem value="clear" sx={{ justifyContent: 'flex-end', '&.Mui-selected': { backgroundColor: 'initial' } }}>
          <Button disabled={!selectedMinOrder || selectedMinOrder?.length <= 0} variant="contained">
            Clear
          </Button>
        </MenuItem>
        {minOrderList.map(({ text: minimumValueText }) => (
          <MenuItem value={minimumValueText} key={`minimum-${minimumValueText}`}>
            <Radio
              checked={minimumValueText === selectedMinOrder}
              value={minimumValueText}
              name="minimum-value-radio-button"
              inputProps={{ 'aria-label': `minimum-value-radio-button-${minimumValueText}` }}
            />
            <ListItemText primary={minimumValueText} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MinimumAmountFilter;
