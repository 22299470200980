import PropTypes from 'prop-types';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { noOp } from 'utils/functions';

const SelectDefault = ({ label = '', selectedValue = '', handleSelectDefaultChange = noOp, selectOptions = '' }) => {
  const htmlLabel = label.split(' ').join('-');

  return (
    <FormControl fullWidth>
      <InputLabel id={`${htmlLabel}-select-label`} sx={{ textTransform: 'capitalize' }}>
        {label}
      </InputLabel>
      <Select
        labelId={`${htmlLabel}-select-label`}
        id={`${htmlLabel}-select`}
        value={selectedValue}
        onChange={handleSelectDefaultChange}
        IconComponent={KeyboardArrowDownIcon}
        input={<OutlinedInput label={label} />}
        inputProps={{ 'data-testid': `category-filters__input-${htmlLabel}` }}
      >
        {selectOptions.map((option) => (
          <MenuItem
            sx={{ color: '#000000' }}
            value={option.value}
            key={`sort-by-${option.value}`}
            data-testid={`category-filters__input-sort--${option.value}`}
          >
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectDefault;

SelectDefault.propTypes = {
  label: PropTypes.string,
  selectedValue: PropTypes.string,
  handleSelectDefaultChange: PropTypes.func,
  selectOptions: PropTypes.arrayOf(PropTypes.shape({})),
};
