import PropTypes from 'prop-types';

import { Button } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import useAuth from 'hooks/useAuth';
import useSignupPopup from 'hooks/useSignupPopup';

const MAX_PAGINATION_SIZE = 10000;

const LoadMore = ({ viewed, total, resource, progress, onClickHandler, isLoading }) => {
  const { isAuthenticated } = useAuth();

  const { handleShowSignupPopup } = useSignupPopup();

  const handleClick = () => {
    if (isAuthenticated) {
      onClickHandler();
    } else {
      handleShowSignupPopup();
    }
  };

  return (
    total > 0 && (
      <Stack>
        <Typography data-testid="load-more-text">{`You have viewed ${viewed} of ${
          total === MAX_PAGINATION_SIZE ? `${MAX_PAGINATION_SIZE}+` : total
        } ${resource}`}</Typography>
        <LinearProgress
          aria-label="Progress bar for Load More"
          sx={{ my: 1 }}
          variant="determinate"
          value={progress || 0}
          data-testid="load-more-progress"
        />
        {progress !== 100 && (
          <Button
            sx={{ mt: 0.5 }}
            loading={isLoading}
            variant="outlined"
            onClick={handleClick}
            data-testid="load-more-button"
          >
            <span className="notranslate">Load More</span>
          </Button>
        )}
      </Stack>
    )
  );
};

LoadMore.propTypes = {
  viewed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  resource: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClickHandler: PropTypes.func.isRequired,
};

export default LoadMore;
