// hooks
import useFilters from 'hooks/useFilters';
import useCurrency from 'hooks/useCurrency';

// utils/const
import useBrowserQueryParams from 'hooks/utils/useBrowserQueryParams';
import { filterUrlQueryParams, getPriceList } from 'constants/category-filters';

// components
import SelectRadio from '../common/SelectRadio';

const PriceFilter = () => {
  const { currency } = useCurrency();
  const { ACTION_TYPES, dispatch, filtersState, setShowLoadingState, handleScrollToTop } = useFilters();
  const { updateBrowserQueryParams, deleteBrowserQueryParams } = useBrowserQueryParams();

  const handlePriceChange = ({ target: { value } }) => {
    handleScrollToTop();

    setShowLoadingState(true);

    if (value === 'clear') {
      deleteBrowserQueryParams(filterUrlQueryParams.PRICE, filterUrlQueryParams.CURRENCY);
      dispatch({
        type: ACTION_TYPES.UPDATE_FILTER,
        stateUpdate: {
          price: '',
          currency: '',
        },
      });
      return;
    }
    const { value: priceValue, text } = getPriceList(currency.symbol).find((price) => price.value === value);

    const stateUpdate = { price: text };
    const queryUpdate = { [filterUrlQueryParams.PRICE]: priceValue, [filterUrlQueryParams.CURRENCY]: currency.name };

    dispatch({ type: ACTION_TYPES.UPDATE_FILTER, stateUpdate });
    updateBrowserQueryParams(queryUpdate);
  };

  const handleOnClose = async (event) => {
    let selectedPrevValue;

    if (event.target.nodeName === 'INPUT') {
      selectedPrevValue = getPriceList(currency.symbol).find(
        (price) => Number(price.value) === Number(event.target.value)
      )?.text;
    }

    if (event.target.nodeName === 'SPAN' || event.target.nodeName === 'FONT') {
      selectedPrevValue = event.target.innerText;
    }

    if (filtersState.price && selectedPrevValue === filtersState.price) {
      handleScrollToTop();
      await dispatch({ type: ACTION_TYPES.UPDATE_FILTER, stateUpdate: { price: '', currency: '' } });
      await deleteBrowserQueryParams(filterUrlQueryParams.PRICE, filterUrlQueryParams.CURRENCY);
    }
  };

  return (
    <SelectRadio
      label="price"
      handleOnClose={handleOnClose}
      handleOnChange={handlePriceChange}
      selectOptions={getPriceList(currency.symbol)}
      selectedValue={filtersState.price}
    />
  );
};

export default PriceFilter;
