import { useRouter } from 'next/router';

// mui
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// hooks
import useFilters from 'hooks/useFilters';

// constants
import { countriesList, filterUrlQueryParams } from 'constants/category-filters';

const CountriesFilter = () => {
  const router = useRouter();
  const { query } = router;

  const { selectedCountries, setShowLoadingState, setSelectedCountries, addFiltersToStack, handleScrollToTop } =
    useFilters();

  const handleCountriesChange = (event) => {
    handleScrollToTop();

    const {
      target: { value },
    } = event;

    setShowLoadingState(true);

    setSelectedCountries(
      // On autofill, we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );

    addFiltersToStack({
      filterKey: filterUrlQueryParams.COUNTRIES,
      filterValues: value,
    });

    if (value.length) {
      return router.push(
        {
          ...router,
          query: {
            ...router.query,
            [filterUrlQueryParams.COUNTRIES]: value.join(','),
          },
        },
        undefined,
        { scroll: false }
      );
    }

    return router.push(
      {
        ...router,
        scroll: false,
        query: Object.fromEntries(Object.entries(query).filter(([key]) => key !== filterUrlQueryParams.COUNTRIES)),
      },
      undefined,
      { scroll: false }
    );
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="country-select-label">Country</InputLabel>

      <Select
        labelId="country-select-label"
        id="country-select"
        multiple
        value={selectedCountries}
        onChange={handleCountriesChange}
        IconComponent={KeyboardArrowDownIcon}
        input={<OutlinedInput label="Country" />}
        inputProps={{ 'data-testid': 'category-filters__input-countries' }}
        renderValue={() => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selectedCountries.map((value) => (
              <Chip key={value} label={value} size="small" />
            ))}
          </Box>
        )}
      >
        {countriesList.map((countryData) => (
          <MenuItem
            value={countryData.countryCode}
            key={`country-${countryData.countryName}`}
            data-testid={`category-filters__option-country--${countryData.countryName}`}
          >
            <Checkbox checked={selectedCountries.indexOf(countryData.countryCode) > -1} />
            <ListItemText primary={countryData.countryName} secondary="" />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CountriesFilter;
