import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import AdvertisingWrapper from './AdvertisingWrapper';

import ItemContainer from './ItemContainer';
import ItemWrapper from './ItemWrapper';

const ListingPage = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'sx',
})(() => {});

ListingPage.ItemContainer = ItemContainer;
ListingPage.Item = ItemWrapper;
ListingPage.AdvertiseWrapper = AdvertisingWrapper;

export default ListingPage;
