import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { noOp } from 'utils/functions';

const selectCheckboxDefaultPropSelectedValues = ['prop-types'];
const selectCheckboxDefaultPropSelectOptions = [];

const SelectCheckbox = ({
  label = 'prop-types',
  handleOnChange = noOp,
  selectedValues = selectCheckboxDefaultPropSelectedValues,
  selectOptions = selectCheckboxDefaultPropSelectOptions,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="country-select-checkbox-label" sx={{ textTransform: 'capitalize' }}>
        {label}
      </InputLabel>

      <Select
        multiple
        id={`${label}-select`}
        value={selectedValues}
        onChange={handleOnChange}
        IconComponent={KeyboardArrowDownIcon}
        input={<OutlinedInput label={label} />}
        labelId={`${label}-select-checkbox-label`}
        inputProps={{ 'data-testid': `category-filters__input-${label}` }}
        renderValue={() => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selectedValues.map((value) => {
              const { name } = selectOptions.find((o) => o.slug === value);
              return <Chip key={value} label={name} size="small" />;
            })}
          </Box>
        )}
      >
        {selectOptions.map((option) => (
          <MenuItem
            value={option.slug}
            key={`${label}-${option.slug}`}
            data-testid={`category-filters__option-${label}--${option.slug}`}
          >
            <Checkbox checked={selectedValues.indexOf(option.slug) > -1} />
            <ListItemText primary={option.name} secondary="" />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectCheckbox;

SelectCheckbox.propTypes = {
  label: PropTypes.string,
  handleOnChange: PropTypes.func,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};
