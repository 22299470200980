/** Utils / Consts */
import {
  brandValuesList,
  categoriesList,
  countriesList,
  filterUrlQueryParams,
  leadTimesList,
  minOrderList,
  getPriceList,
  sortByStorePageList,
  sortByList,
} from 'constants/category-filters';

/**
 * create a query string of valid filters values
 * @type function
 * @param {object} - Filters values Object
 * @returns {object} - return filter params query string
 */
export const getFilterURLParamsString = (
  {
    limit,
    offset,
    sortBy,
    onOffer,
    minOrder,
    category,
    countries,
    collection,
    brandValues,
    leadTimeMaxDays,
    shippingCountryCode,
    flairs,
  } = {},
  isFirstParam = false
) => {
  const query = new URLSearchParams({
    ...(limit && { limit }),
    ...(offset && { offset }),
    ...(sortBy && { sortBy }),
    ...(onOffer && { onOffer }),
    ...(minOrder && { minOrder }),
    ...(collection && { collection }),
    ...(category && { category: [category] }),
    ...(shippingCountryCode && { shippingCountryCode }),
    ...(countries && { countries }),
    ...(brandValues && { [filterUrlQueryParams.BRAND_VALUE]: brandValues }),
    ...(leadTimeMaxDays && { [filterUrlQueryParams.LEAD_TIME_MAX_DAYS]: leadTimeMaxDays }),
    ...(flairs && { flairs }),
  });
  const paramPrefix = isFirstParam ? '' : '&';
  const paramsString = query.toString() ? `${paramPrefix}${decodeURIComponent(query.toString())}` : '';
  return paramsString;
};

/**
 * Extracts only the cookie value with the correspondent name, if exists
 * @type function
 * @param initialQueryParams {object} - Filter Query Params values Object
 * @returns {object} - return filter values
 */
export const setInitialFilters = (initialQueryParams, allFlairFilterOptions) => {
  const brandValuesQueryValue = brandValuesList
    .filter((brandValue) => initialQueryParams[filterUrlQueryParams.BRAND_VALUE]?.split(',').includes(brandValue.value))
    .map((brandValueData) => {
      return brandValueData.value;
    });

  const countriesFromUrl = initialQueryParams[filterUrlQueryParams.COUNTRIES];
  let countriesArray = [];

  if (Array.isArray(countriesFromUrl)) {
    countriesArray = countriesFromUrl;
  } else if (typeof countriesFromUrl === 'string') {
    countriesArray = countriesFromUrl.split(',');
  }

  const countriesQueryValue = countriesList
    .filter((country) => countriesArray.map((e) => e.toLowerCase()).includes(country.countryCode.toLowerCase()))
    .map((countryData) => countryData.countryCode);

  const onOfferQueryValue = initialQueryParams[filterUrlQueryParams.ON_OFFER]?.toString().toLowerCase();
  const [{ text: minOrderQueryValue } = {}] = minOrderList.filter(
    ({ slug: minOrderSlug }) => minOrderSlug === initialQueryParams[filterUrlQueryParams.MIN_ORDER]
  );
  const [sortByQueryValue] = sortByList.filter(
    (val) => val.toLowerCase() === initialQueryParams[filterUrlQueryParams.SORT_BY]?.toLowerCase()
  );

  const [leadTimeQueryValue] = leadTimesList.filter(
    ({ maximumDays }) => Number(maximumDays) === Number(initialQueryParams[filterUrlQueryParams.LEAD_TIME_MAX_DAYS])
  );

  const categoriesQueryValue = categoriesList
    .filter((category) =>
      initialQueryParams.category
        ?.split(',')
        .map((e) => e.toLowerCase())
        .includes(category.slug.toLowerCase())
    )
    .map((categoryData) => {
      return categoryData.slug;
    });

  const flairsInQuery = initialQueryParams.flairs?.split(',').map((e) => e.toLowerCase());
  const flairsQueryValue = allFlairFilterOptions?.reduce((acc, flair) => {
    if (flair?.flairType === 'static' && flairsInQuery?.includes(flair?.flairId)) {
      acc.push(flair?.flairId);
    }
    if (flair?.flairType === 'dynamic' && flairsInQuery?.includes(flair?.code)) {
      acc.push(flair?.code);
    }
    return acc;
  }, []);

  const initialTab = initialQueryParams.tab === 'products' ? 'products' : 'brands';

  const storeSortByQueryValue = sortByStorePageList.filter(
    ({ value }) => value === initialQueryParams[filterUrlQueryParams.SORT_BY]?.toLowerCase()
  )[0]?.value;

  const bundleQueryValue = initialQueryParams[filterUrlQueryParams.BUNDLE]?.toString().toLowerCase();
  const collectionsQueryValue = initialQueryParams[filterUrlQueryParams.COLLECTION]?.split(',');

  const currencyQueryValue = initialQueryParams[filterUrlQueryParams.CURRENCY];

  const priceQueryValue = getPriceList().filter(
    ({ value }) => value === Number(initialQueryParams[filterUrlQueryParams.PRICE])
  )[0]?.value;

  return {
    sortByQueryValue,
    onOfferQueryValue,
    leadTimeQueryValue,
    minOrderQueryValue,
    countriesQueryValue,
    brandValuesQueryValue,
    categoriesQueryValue,
    initialTab,
    priceQueryValue,
    bundleQueryValue,
    storeSortByQueryValue,
    currencyQueryValue,
    collectionsQueryValue,
    flairsQueryValue,
  };
};
