import { useState, useLayoutEffect, useRef } from 'react';

const Browsers = {
  Chrome: 'Chrome',
  Firefox: 'Firefox',
  Safari: 'Safari',
  Edge: 'Edge',
  Opera: 'Opera',
  IE: 'IE',
  Unknown: 'Unknown',
};

function getCurrentBrowser() {
  const { userAgent } = navigator;

  if (/Chrome/.test(userAgent) && !/Edge|OPR/.test(userAgent)) return Browsers.Chrome;
  if (/Firefox/.test(userAgent)) return Browsers.Firefox;
  if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) return Browsers.Safari;
  if (/Edg/.test(userAgent)) return Browsers.Edge;
  if (/OPR/.test(userAgent)) return Browsers.Opera;
  if (/MSIE|Trident/.test(userAgent)) return Browsers.IE;

  return Browsers.Unknown;
}

/**
 *@return {[Object,Function]} returns [state, setState] array
 */
function useScrollToRef(propRef = null) {
  const ref = useRef(propRef);
  const [shouldScrollTo, setShouldScrollTo] = useState(false);

  useLayoutEffect(() => {
    const scrollToRef = document.querySelector('[data-scroll-to-element="scroll-reference-id"]');
    const currentBrowser = getCurrentBrowser();
    if (scrollToRef) {
      if (currentBrowser === Browsers.Safari) {
        const rect = scrollToRef.getBoundingClientRect();
        window.scrollTo({
          top: rect.top + window.scrollY - window.innerHeight / 2,
          left: rect.left + window.scrollX - window.innerWidth / 2,
          behavior: 'instant',
        });
      } else {
        scrollToRef.scrollIntoView({
          behavior: 'instant',
          block: 'center',
          inline: 'center',
        });
      }
    }
  }, [shouldScrollTo]);

  return [ref, setShouldScrollTo];
}

export { useScrollToRef };
