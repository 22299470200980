import { useRouter } from 'next/router';

// mui
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// hooks
import useFilters from 'hooks/useFilters';

// constants
import { categoriesList, filterUrlQueryParams } from 'constants/category-filters';

const CategoriesFilter = () => {
  const router = useRouter();
  const { selectedCategories, setShowLoadingState, setSelectedCategories, addFiltersToStack, handleScrollToTop } =
    useFilters();

  const { query } = router;

  const handleOnCategoriesChange = (event) => {
    handleScrollToTop();

    const {
      target: { value },
    } = event;

    setShowLoadingState(true);

    setSelectedCategories(
      // On autofill, we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );

    addFiltersToStack({ filterKey: filterUrlQueryParams.CATEGORY, filterValues: value });

    if (value.length) {
      return router.push(
        {
          ...router,
          query: {
            ...router.query,
            [filterUrlQueryParams.CATEGORY]: value.map((e) => e.toLowerCase()).join(','),
          },
        },
        undefined,
        { scroll: false }
      );
    }

    return router.push(
      {
        ...router,
        query: Object.fromEntries(Object.entries(query).filter(([key]) => key !== filterUrlQueryParams.CATEGORY)),
      },
      undefined,
      { scroll: false }
    );
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="category-select-label">Category</InputLabel>

      <Select
        labelId="category-select-label"
        id="category-select"
        multiple
        value={selectedCategories}
        onChange={handleOnCategoriesChange}
        IconComponent={KeyboardArrowDownIcon}
        input={<OutlinedInput label="Category" />}
        inputProps={{ 'data-testid': 'category-filters__input-categories' }}
        renderValue={() => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selectedCategories.map((value) => (
              <Chip key={value} label={value} size="small" />
            ))}
          </Box>
        )}
      >
        {categoriesList.map((categoryData) => (
          <MenuItem
            value={categoryData.slug}
            key={`category-${categoryData.name}`}
            data-testid={`category-filters__option-category--${categoryData.slug}`}
          >
            <Checkbox checked={selectedCategories.indexOf(categoryData.slug) > -1} />

            <ListItemText primary={categoryData.name} secondary="" />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CategoriesFilter;
